<template>
  <select class="form-control" style="width: 100%"  id="select2ajaxcustomer-line-template">
  </select>
</template>

<script>
import $ from "jquery";
import 'select2';

export default {
  name: 'select-2-ajax-customer-line',
  template: '#select2ajaxcustomer-line-template',
  data() {
    return {
      bInitialFlag: false,
      select2Instance: null
    }
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number],
      default: ''
    },
    store_id: {
      type: [String, Number],
      default: ''
    }
  },
  methods: {
    initialValue: function(id,name){
      this.bInitialFlag = true;
      var newOption = new Option(name, id); 
      $(this.$el).append(newOption).trigger('change');
    },
    setValue(id, name) {
      const newOption = new Option(name, id, true, true); 
      $(this.$el)
        .empty()
        .append(newOption)
        .trigger("change");
    },

    initSelect2() {
      
      let vm = this;

      this.select2Instance = $(this.$el).select2({
        width: '100%',
        dropdownParent: $(this.$el).parent(),
        ajax: {
          url: this.HttpServices.generate_url('/ab_order/line-users'),
          headers : this.HttpServices.createHeader(),
          dataType: 'json',
          delay: 500,
          cache: false,
          data: (params) => { 
            return {
              keyword: params.term || '',
              store_id: vm.store_id || '',
            };
          },
          processResults: (response) => {
            const data = response.data;
            if (data && data.length > 0) {
              const result_data = data.map((obj) => ({
                id: obj.customer_id || '0',
                text: obj.display_name,
                uuid: obj.line_user_id, 
              }));
             
              return { results: result_data };
            }
            return { results: [] }; 
          },
          error: (error) => {
            console.error('Ajax error:', error);
          }
        },
        placeholder: 'โปรดพิมพ์อย่างน้อย 1 ตัวอักษร',
        minimumInputLength: 1,
        allowClear: true,
        templateResult: function(item) { 
          if (!item.text) return item.text; 
          return `${item.id || 'No ID'} : ${item.text}`; 
        },
        templateSelection: function(item) { 
          if (!item.text) return item.text; 
          return `${item.id || 'No ID'} : ${item.text}`;
        },
      }).val(this.value)
      .on('change', function() { 
        if(vm.bInitialFlag){
          vm.bInitialFlag = false;
        }else{ 
          vm.$emit('change', this.value);
        }
        
      });

      // Handle change events
      this.select2Instance.on('select2:open', () => {
        document.querySelector('.select2-search__field').focus();
      });

      this.select2Instance.on('change', function(e) {
        const selectedValue = $(this).val();
        const selectedData = $(this).select2('data')[0];
        
        vm.$emit('change', { customer_id: $(this).val(), line_user_id: selectedData.uuid });
      });

      $('.select2-results__option').hover(
        function () {
          $(this).addClass('select2-results__option--highlighted');
        },
        function () {
          $(this).removeClass('select2-results__option--highlighted');
        }
      );
      
      if (this.value) { 
        $(this.$el).val(this.value).trigger('change');
      }
    },

    destroySelect2() {
      if (this.select2Instance) {
        this.select2Instance.select2('destroy');
        this.select2Instance = null;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initSelect2();
    });
  },
  beforeDestroy() {
    this.destroySelect2();
  },
  watch: {
    value: function(value) {
      // update value
      $(this.$el).val(value);
    },
    options: function(options) {
      // update options
      $(this.$el)
        .empty()
        .select2({ data: options });
    }
  }
};
</script>

<style>
.select2-container {
  width: 100% !important;
}
.select2-container--default .select2-results__option {
  cursor: pointer;
  background-color: transparent;
}

.select2-container--default .select2-results__option--highlighted {
  background-color: #e9ecef;
  color: #000;
}
</style>