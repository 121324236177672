<template>
  <div>
    <b-modal id="dialog_send_msg_to_line" size="xl" @show="onModalShow">
      <template #modal-title>
        <h3> ส่งข้อความหาลูกค้าผ่าน LINE Chatbot </h3>
      </template>
      <div>
        <div class="row">
          <div class="col-12">
            <div class="mb-4">
              <label class="form-label">Line ลูกค้า</label>
              <Select2CustomerLine
                :value="item.line"
                :store_id="store_id"
                @change="handleCustomerChange"
                ref="Select2CustomerLine"
              ></Select2CustomerLine>
              <base-input
                v-if="item.line"
                name="line"
                placeholder="ชื่อ Line ลูกค้า"
                v-model="item.line"
                :rules="{ required: true }"
              ></base-input>
            </div>
          </div>
          <div class="col-12">
            <label>For Customer</label>
            <textarea rows="20" class="form-control" v-model="templateCustomer"></textarea>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <b-button pill variant="info" @click="sendMSGToLine('A')">ยืนยันส่งข้อความ</b-button>
        <b-button variant="secondary" @click="close()">
          <i class="fa fa-times"></i> ยกเลิก
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Select2CustomerLine from "../component/select2.customer_line.vue";
// import axios from "axios";

export default {
  name: "dialog-send-msg-to-line",
  components: {
    Select2CustomerLine,
  },
  data() {
    return {
      item: {
        id: null,
        line: null,
      },
      isInitialized: false,
      templateCustomer: '',
    };
  },
  props: ["customer","templateCustomer", "store_id"],
  methods: {
    reset() {
      this.$emit("change", null); 
    },
    close() {
      this.$bvModal.hide("dialog_send_msg_to_line");
    },
    handleCustomerChange(selectedValue) {  
      if (selectedValue) {
        this.item.line = selectedValue.text;
        this.item.id = selectedValue.customer_id;
        this.item.line_user_id = selectedValue.line_user_id;
      } 
    },
    async onModalShow() { 
      if (this.templateCustomer) {
        this.templateCustomer = this.templateCustomer.split('\n').slice(0, -3).join('\n');
      }
      try {
        const response = await this.HttpServices.getData(
          // `${process.env.VUE_APP_CHAT_BOT_API_URL}/api/line-users?customer_id=${this.customer.id}&keyword=${this.customer.line}`,
          `/ab_order/line-users?customer_id=${this.customer.id}&keyword=${this.customer.line}&store_id=${this.store_id}`
        );

        const result = response.data; 
        console.log("result", result);

        if (result && result.length > 0) { 
          
          const lineUsers = result
            .filter((item) => item.customer && item.customer.id !== null)
            .map((item) => ({
              id: item.customer_id,
              text: item.display_name,
            }));

          if (lineUsers.length > 0) { 
            if (this.$refs.Select2CustomerLine) { 
              this.$refs.Select2CustomerLine.initialValue(lineUsers[0].id, lineUsers[0].text);
               
            }
          } 
        }
      } catch (error) {
        console.error("Error fetching line users:", error);
        this.item = {};
      }
    },
 
    async sendMSGToLine() { 
      const data = this.item.id !== 0 ? {
        customer_id: this.customer.id,
        line_user_id: this.item.line_user_id,
        store_id: this.store_id,
        message: this.templateCustomer,
      } : {
        line_user_id: this.item.line_user_id,
        message: this.templateCustomer,
      };

      try {
        const response = await this.HttpServices.postData(
          `/ab_order/send-message-to-line-user`,
          data
        );
        // console.log("response.data", response.data);
        // console.log("response", response);
        if (response.data.message == 200) {
          this.AlertUtils.alertCallback("success", `ส่งข้อความหาลูกค้าสำเร็จ`, () => {
            //this.$router.push("/order_new");
            this.close();
          });
          
        } else {
          this.AlertUtils.alert("error", `ส่งข้อความหาลูกค้าไม่สำเร็จ`);
          this.close();
        }

      } catch (error) {
        console.error("Error sending message to LINE user:", error);
      }
    }
  },
 
};
</script>
